import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['nextTip', 'speechBubble'];

  connect() {
    document.getElementById('next-button').classList.add('display-none');
  }

  respond(event) {
    this.speechBubbleTargets.forEach((bubble) => {
      var items = JSON.parse(this.nextTipTarget.dataset.items);
      var index = parseInt(this.nextTipTarget.dataset.index);
      var nextIndex = index + 1;
      if (nextIndex == Object.keys(items).length - 1) {
        document.getElementById('next-button').classList.remove('display-none');
      }
      if (nextIndex == Object.keys(items).length) {
        nextIndex = 0;
      }
      bubble.innerHTML = items[nextIndex];
      this.nextTipTarget.dataset.index = nextIndex;
    });
  }
}
