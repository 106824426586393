import tinymce from 'tinymce/tinymce';
import Rails from '@rails/ujs';

const uploadUrl = '/dashboard/tinymce_images';
const imageUploadHandler = (blobInfo, progress) =>
  new Promise((success, failure) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', uploadUrl);
    xhr.withCredentials = false;
    Rails.CSRFProtection(xhr);
    xhr.upload.onprogress = (e) => {
      progress((e.loaded / e.total) * 100);
    };
    xhr.onerror = () => {
      failure(
        'Image upload failed due to a XHR Transport error. Code: ' + xhr.status
      );
    };
    xhr.onload = () => {
      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }
      const json = JSON.parse(xhr.responseText);
      if (!json || typeof json.location != 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }
      success(json.location);
    };
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    let richText = document.querySelector('.rich-text');
    const richTextId = richText.dataset.richTextId;
    formData.append('rich_text_id', richTextId);
    xhr.send(formData);
  });

tinymce.init({
  selector: '.rich-text',
  plugins: 'code image',
  toolbar: [
    { name: 'history', items: ['undo', 'redo'] },
    { name: 'styles', items: ['styles', 'code'] },
    { name: 'formatting', items: ['bold', 'italic'] },
    {
      name: 'alignment',
      items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify']
    },
    { name: 'indentation', items: ['outdent', 'indent'] },
    { name: 'images', items: ['image'] }
  ],
  base_url: '/tinymce',
  images_upload_handler: imageUploadHandler
});
