import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['speechBubble'];
  static values = { id: String, url: String };

  respond(event) {
    this.speechBubbleTargets.forEach((bubble) => {
      bubble.innerHTML = event.currentTarget.dataset.response;
    });
    let formData = new FormData();
    formData.append(
      'managing_common_responses_selection[selection]',
      event.currentTarget.dataset.response
    );
    formData.append(
      'managing_common_responses_selection[activities_managing_common_response_id]',
      this.idValue
    );
    Rails.ajax({
      url: this.urlValue,
      type: 'POST',
      data: formData,
      dataType: 'json'
    });
  }
}
