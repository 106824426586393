import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { url: String };

  print() {
    let formData = new FormData();
    formData.append('to_do_list_event[kind]', 'print');
    Rails.ajax({
      url: this.urlValue,
      type: 'POST',
      data: formData,
      dataType: 'json'
    });
    window.print();
  }
}
