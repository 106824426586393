import { Controller } from '@hotwired/stimulus';
import { MDCRipple } from '@material/ripple';
import { MDCList } from '@material/list';

export default class extends Controller {
  connect() {
    this.list = new MDCList(this.element);
    const listItemRipples = this.list.listElements.map(
      (listItemEl) => new MDCRipple(listItemEl)
    );
  }

  disconnect() {
    if (this.list) {
      this.list.destroy();
    }
  }
}
