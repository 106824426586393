import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['content'];

  toggle() {
    this.contentTarget.classList.toggle('display-none');
  }
}
