import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { url: String };

  follow() {
    let formData = new FormData();
    let url = event.target.href;
    formData.append('refresher_link_click[url]', url);
    Rails.ajax({
      url: this.urlValue,
      type: 'POST',
      data: formData,
      dataType: 'json'
    });
  }
}
