import Rails from '@rails/ujs';
import { RDAudioEventCapture } from 'raindrops';

var lastPercentComplete = 0;

// 0 - 100 percent by 5's
const percentCompleteBenchmarks = Array.from(
  { length: 21 },
  (value, index) => 0 + 5 * index
);

const percentCompleteInputName = 'audio_action[current_playback_percent]';

// save percent complete in the input element to send to the server
function storePercentComplete(audioForm, percentComplete) {
  var percentCompleteInput = audioForm.querySelector(
    `[name='${percentCompleteInputName}']`
  );
  if (percentCompleteInput == null) {
    audioForm.insertAdjacentHTML(
      'beforeend',
      `<input name="${percentCompleteInputName}" value="${percentComplete}">`
    );
  } else {
    percentCompleteInput.value = percentComplete;
  }
}

RDAudioEventCapture.initialize(
  function (audioForm, audio, event) {
    if (event.type == 'timeupdate') {
      if (audio.currentTime == audio.duration) {
        if (lastPercentComplete == 100) {
          return;
        }
        lastPercentComplete = 100;
        storePercentComplete(audioForm, 100);
      } else {
        const rawPercentComplete = 100 * (audio.currentTime / audio.duration);
        const roundedPercentComplete = percentCompleteBenchmarks.find(
          (b) => lastPercentComplete < b && rawPercentComplete > b
        );
        lastPercentComplete = rawPercentComplete;
        if (roundedPercentComplete != null) {
          storePercentComplete(audioForm, roundedPercentComplete);
        } else {
          return;
        }
      }
    }

    Rails.fire(audioForm, 'submit');
  },
  ['play', 'pause', 'ended', 'timeupdate']
);
