import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    let passwordInput = document.querySelector('#participant_password');
    let icon = this.element.querySelector('i');
    this.element.addEventListener('click', () => {
      let type =
        passwordInput.getAttribute('type') == 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
      icon.classList.toggle('fa-eye');
      icon.classList.toggle('fa-eye-slash');
    });
  }
}
