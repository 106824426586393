import { Controller } from '@hotwired/stimulus';
import { MDCBanner, CloseReason } from '@material/banner';

export default class extends Controller {
  static values = { shouldOpenNow: Boolean };

  connect() {
    this.banner = new MDCBanner(this.element);

    if (this.shouldOpenNowValue) {
      this.banner.open();
    }
    this.addEventListeners();
  }

  disconnect() {
    if (this.banner) {
      this.banner.destroy();
    }
  }

  open(event) {
    if (event != null) {
      this.banner.setText(event.detail);
    }
    this.banner.open();
  }

  close() {
    this.banner.close();
  }

  addEventListeners() {
    document.body.addEventListener('ajax:success', (event) => {
      const [data, _status, _xhr] = event.detail;

      if (data.message == null) {
        return;
      }

      this.banner.setText(data.message);
      this.banner.open();
    });
  }
}
