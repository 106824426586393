import Rails from '@rails/ujs';
import { RDVimeoEventCapture } from 'raindrops';
import Player from '@vimeo/player';

const requiredCompletionPercent = 96;
let videoIds = [];
let captureTimeupdateSeconds = [];

// 0 - 100 percent by 5's
const percentCompleteBenchmarks = Array.from(
  { length: 21 },
  (value, index) => 0 + 5 * index
);

document.querySelectorAll('.vimeo-video').forEach(function (video) {
  videoIds.push(video.dataset.videoId);
  calculateVideoEventSeconds(video);
});

RDVimeoEventCapture.initialize(
  Player,
  function (videoForm) {
    convertPercentToInteger(videoForm);
    if (
      ['play', 'pause', 'ended', 'error', 'seeked'].includes(
        eventKind(videoForm)
      )
    ) {
      submitForm(videoForm);
    } else if (eventKind(videoForm) == 'timeupdate') {
      if (allVideosComplete(videoForm)) {
        enableNextNavigation(videoForm);
        var input = document.getElementsByName('video_action[kind]')[0];
        input.value = 'completed';
      }
      submitForm(videoForm);
    }
  },
  ['play', 'pause', 'ended', 'timeupdate', 'error', 'seeked']
);

function calculateVideoEventSeconds(video) {
  let player = new Player(video);
  player.getDuration().then(function (totalSeconds) {
    percentCompleteBenchmarks.forEach((percentage) => {
      captureTimeupdateSeconds.push(
        Math.floor((parseFloat(totalSeconds) * percentage) / 100)
      );
    });
  });
}

function allVideosComplete(videoForm) {
  if (playbackPercent(videoForm) >= requiredCompletionPercent) {
    videoIds = videoIds.filter((id) => id != videoId(videoForm));
  }

  return videoIds.length == 0;
}

function eventKind(videoForm) {
  return videoForm.querySelector("[name='video_action[kind]']").value;
}

function playbackPercent(videoForm) {
  return videoForm.querySelector(
    "[name='video_action[current_playback_percent]']"
  ).value;
}

function convertPercentToInteger(videoForm) {
  const currentPlaybackPercent = playbackPercent(videoForm);
  const currentPlaybackPercentInteger = parseFloat(currentPlaybackPercent);

  videoForm.querySelector(
    "[name='video_action[current_playback_percent]']"
  ).value = Math.round(currentPlaybackPercentInteger * 100);
}

function videoId(videoForm) {
  return videoForm.querySelector("[name='video_action[vimeo_id]']").value;
}

function enableNextNavigation(videoForm) {
  let navigationDisabled = document.getElementById('video-disabled-navigation');
  if (navigationDisabled) {
    submitForm(videoForm);
    navigationDisabled.classList.add('display-none');
    document
      .getElementById('video-enabled-navigation')
      .classList.remove('display-none');
  }
  let videoMarker = document.querySelector(
    '[data-multiple-choice-question-video-complete-value]'
  );
  if (videoMarker) {
    videoMarker.dataset.multipleChoiceQuestionVideoCompleteValue = 'true';
  }
}

function submitForm(videoForm) {
  if (eventKind(videoForm) == 'timeupdate') {
    let currentSecond = videoForm.querySelector(
      "[name='video_action[current_playback_time_in_s]']"
    ).value;
    let videoMarker = document.querySelector('[data-last-second-value]');
    if (videoMarker) {
      for (let secondsTarget of captureTimeupdateSeconds) {
        if (
          parseFloat(videoMarker.dataset.lastSecondValue) < secondsTarget &&
          secondsTarget < parseFloat(currentSecond)
        ) {
          Rails.fire(videoForm, 'submit');
          break;
        }
      }
      videoMarker.dataset.lastSecondValue = currentSecond;
    }
  } else if (eventKind(videoForm) == 'pause') {
    if (playbackPercent(videoForm) !== 100) {
      Rails.fire(videoForm, 'submit');
    }
  } else {
    Rails.fire(videoForm, 'submit');
  }
}
